import { kebabCase } from 'lodash';
import { Link } from 'gatsby';
import { HTMLContent } from '../content';
import Container from '@mui/material/Container';

export type BlogPostParams = {
  content: string,
  description: string,
  tags: Array<string>,
  title: string,
  helmet: Node,
  featuredImageUrl: string
}

const BlogPost = ({
  content,
  description,
  tags,
  title,
  helmet,
  featuredImageUrl
}: BlogPostParams) => {

  return (
    <section className="section">
      {helmet || ''}
      <div className="article__hero-image">
        <img src={featuredImageUrl} alt={title} />
      </div>
      <Container maxWidth="md" className="article-container">
        <h1
          style={{ lineHeight: 1.5 }}
          className="title is-size-2 has-text-weight-bold is-bold-light"
        >
          {title}
        </h1>
        <h3>{description}</h3>
        <HTMLContent content={content} className="article-post" />
      </Container>
    </section>
  )
};

export default BlogPost;