export type HTMLContentParams = {
  content: string,
  className: string
};

export type ContentParams = {
  content: Node,
  className: string
}

export const HTMLContent = ({ content, className }: HTMLContentParams) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content, className }: ContentParams) => (
  <div className={className}>{content}</div>
)

export default Content;