import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

import BlogPost from '../components/posts/blogPost';

export type BlogPostTemplateParams = {
  data: any
};

const BlogPostTemplate = ({ data }: BlogPostTemplateParams) => {
  const { markdownRemark: post } = data
  if (!data) {
    return (<></>);
  }
  return (
    <Layout pageTitle={`${post.frontmatter.title}`} description={post.frontmatter.description}>
      <BlogPost
        content={post.html}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Article">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.date} - ${post.frontmatter.description}`}
            />
            <meta
              name="image"
              content={`https://remoteinclusive.com${post.frontmatter.featuredimage.publicURL}`}
            />
            <meta name="twitter:creator" content="@RemoteInclusive" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@RemoteInclusive" />
            <meta name="twitter:title" content={`${post.frontmatter.title}`} />
            <meta name="twitter:description" content={`${post.frontmatter.date} - ${post.frontmatter.description}`} />
            <meta name="twitter:image" content={`https://remoteinclusive.com${post.frontmatter.featuredimage.publicURL}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta property="og:site_name" content="RemoteInclusive" />
            <meta property="og:locale" content="en_US" />
            <meta
              property="og:description"
              content={`${post.frontmatter.date} - ${post.frontmatter.description}`}
            />
            <meta property="og:url" content={`https://remoteinclusive.com${post.frontmatter.slug}`} />
            <meta
              property="og:image"
              content={`https://remoteinclusive.com${post.frontmatter.featuredimage.publicURL}`}
            />
            
            <meta
              name="article:published_time"
              content={post.frontmatter.date}
            />
            <meta name="article:author" content="RemoteInclusive" />
            <meta name="article:section" content="Tech" />
            <meta name="article:tag" content={post.frontmatter.tags} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        url={post.frontmatter.slug}
        featuredImageUrl={post.frontmatter.featuredimage.publicURL}
      />
    </Layout>
  )
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostByID($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          publicURL
        }
        slug
      }
    }
  }
`;
